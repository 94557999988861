const AUTH_CONFIG: object = {
    "domain": process.env.REACT_APP_HOSTED_UI_DOMAIN,
    "scope": [
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
        "api/admin"
    ],
    "redirectSignIn": process.env.REACT_APP_ADMIN_DOMAIN,
    "redirectSignOut": process.env.REACT_APP_ADMIN_DOMAIN,
    "responseType": "token"
};

export default AUTH_CONFIG;


