const AMPLIFY_CONFIG: object = {
    "Auth": {
        "region": "eu-west-1",
        "userPoolId": process.env.REACT_APP_USER_POOL_ID,
        "userPoolWebClientId": process.env.REACT_APP_USER_POOL_CLIENT_ID,
        "mandatorySignIn": false,
        "cookieStorage": {
            "domain": process.env.REACT_APP_COOKIE_DOMAIN,
            "path": "/",
            "expires": 365,
            "secure": true
        },
        "redirectSignIn": process.env.REACT_APP_ADMIN_DOMAIN,
        "redirectSignOut": process.env.REACT_APP_ADMIN_DOMAIN
    }
};

export default AMPLIFY_CONFIG;
