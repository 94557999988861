import React, { ChangeEvent } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { TextField, Button, Flex } from '@aws-amplify/ui-react';
import QRCode from 'qrcode.react';
import awsconfig from './awsconfig'
import awsauth from './awsauth'
import '@aws-amplify/ui-react/styles.css';


type AppProps = {
}

type AppState = {
  user?: CognitoUser,
  totpToken: string,
  accessToken?: string,
  mfaStatus?: string,
  status: string,
  qr_code?: string,
  validTotp: boolean
}

export default class App extends React.Component<AppProps, AppState> {

  constructor(props: any) {
    super(props);
    this.state = {
      status: "HOME",
      totpToken: '',
      validTotp: true
    };
  }

  componentDidMount() {
    Amplify.configure(awsconfig)
    Auth.configure({ oauth: awsauth })
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          this.setState({ user: data })
          break
        case 'signOut':
          this.setState({ user: undefined })
          break
      }
    })
    Auth.currentAuthenticatedUser().then(user => {
      Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken()
        this.setState({
          user,
          accessToken: jwt
        })
      })
      Auth.getPreferredMFA(user, {bypassCache: true}).then((value) => {
        if (value === "NOMFA"){
          this.setState({mfaStatus: "NOMFA"})
        } else if (value === "SOFTWARE_TOKEN_MFA"){
          this.setState({mfaStatus: "SOFTWARE_TOKEN_MFA"})
        } else {
          this.setState({mfaStatus: undefined})
        }
      })
    }).catch(() => console.log('Not signed in'))
  }

  requestTotpCode() {
    Auth.setupTOTP(this.state.user).then((code) => {
      this.setState({
        qr_code: "otpauth://totp/API " + process.env.REACT_APP_STAGE + "?secret=" + code + "&issuer=ThermoVault",
        status: "VALIDATE_TOTP"});
    });
  }

  validateTotp() {
    Auth.verifyTotpToken(this.state.user, this.state.totpToken).then(() => {
      Auth.setPreferredMFA(this.state.user, 'TOTP').then(() => {
        this.setState({
          status: "HOME",
          mfaStatus: "SOFTWARE_TOKEN_MFA",
          validTotp: true
        })
      });
    }).catch(e => {
      this.setState({
        validTotp: false
      })
    });
  }

  render() {
    let mfaStatusText: string = ''
    if (this.state.mfaStatus === undefined) {
      mfaStatusText = ""
    } else if (this.state.mfaStatus === "SOFTWARE_TOKEN_MFA") {
      mfaStatusText = "Multi-factor authentication enabled"
    } else if (this.state.mfaStatus === "NOMFA") {
      mfaStatusText = "Multi-factor authentication disabled"
    }
    return (
      <div className="App">
        <Flex direction="column" margin="2em">
          <Flex direction="row" justifyContent="flex-end">
            {this.state.user === undefined ? <Button onClick={() => Auth.federatedSignIn()}>Log in with ThermoVault</Button> : <></>}
            {this.state.user !== undefined ? <Button onClick={() => Auth.signOut()}>Sign out</Button> : <></>}
          </Flex>
          <Flex direction="column">
            <p style={{ wordBreak: "break-all" }}>{this.state.accessToken}</p>
            {this.state.accessToken !== undefined ? <>
              <Button width="100px" onClick={() => { navigator.clipboard.writeText(this.state.accessToken || '') }}>Copy</Button>
            </> : <></>
            }
          </Flex>
          <Flex direction="column" justifyContent="center" >
            <p>{mfaStatusText}</p>
            {this.state.status === 'HOME' && this.state.mfaStatus === 'NOMFA' ? <>
              <Button width="300px" onClick={() => this.requestTotpCode()}>Setup totp token</Button>
            </> : <></>
            }
            {this.state.status === 'VALIDATE_TOTP' ? <>
              <p>Scan the code and verify using a token</p>
              <QRCode value={this.state.qr_code || ''} />
              <TextField hasError={!this.state.validTotp} errorMessage="Could not verify token" label="Code" onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ totpToken: e.target.value })} />
              <Button onClick={() => this.validateTotp()}>Activate totp</Button>
            </> : <></>
            }
          </Flex>
        </Flex>
      </div>
    );
  }
}